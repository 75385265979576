// Define all style variables here.

// Colours.
$white: #FFFFFF;
$gray: #f5f5f5;
$searchgray: #9e9e9c;
$black: #000000;

// Brand Colours.
$color-primary: #253068;
$color-secondary: #e84241;
$darkred: #9b382e;
$othered: #bb3739;

// Header Customization

$main-menu-color: #7b7b7b;
$menu-hover-color: #303030;
$underline-menu-color: #707070;
$header-bg-colour: #FFFFFF;
$header-height: 100px;
$burger-menu-colour: #303030;
$burger-menu-bg-colour-1: #fff; // Bakcground colour underneath
$burger-menu-bg-colour-2: #fff; // Background colour on top

// Fonts
@import url("https://use.typekit.net/bco0qln.css");
$heading: 'Verveine' ,sans-serif;
$font: 'poppins',sans-serif;

// Media querys.
$small: "(max-width: 767px)";
$mobile: "(min-width: 750px)";
$tabletmid: "(max-width: 999px) and (min-width: 767px)";
$tablet: "(min-width: 1000px)";
$desktop: "(min-width: 1500px)";
$large: "(min-width: 1600px)";

// Misc.
$max-width: 1100px;
$shadow: 0 5px 10px 0 rgba(0,0,0,.05);
$ease: ease all 0.3s;
$transition-time: 750ms;

// Default classes, add these to elements to prevent repeating yourself.
.default-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.default-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.transition {
    transition: 1s ease;
    transition-delay: .2s;
    transition-timing-function: ease-in-out;
}
 
// SCSS includes.
@mixin default-psuedo {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: $ease;
}

