// Default element styles and main containers.

@import "vars";

* {
	padding: 0px; 
	margin: 0px;
	box-sizing: border-box;
	color: $color-primary;
	font-family: $font;
}

body,
html {
	height: 100%;
}

p {
	width: 100%;
	line-height: 1.45;
	@media #{$small} {
		font-size:15px;
	}
}

h1,
h2 {
	line-height: 1.25;
	font-weight: normal;
	@media #{$small} {
		line-height: .9em;
	}
}

h1 {
	font-size: 50px;
	font-family: $heading;
	color: $color-secondary;
	@media #{$small} {
		font-size: 40px;
	}
}

h2 {
	font-size: 50px;
	@media #{$small} {
		font-size: 40px;
	}
}

h3,
h4 {
	font-size: 50px;
    font-family: $heading;
    font-weight: normal;
    letter-spacing: -1px;
	text-transform: capitalize;
		@media #{$small} {
		font-size: 40px;
	}
}

a {
	transition: $ease;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

ul {
	list-style: none;
}

span,
u,
b,
i,
a,
strong,
em,
blockquote {
	color: inherit;
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
}

i,
em,
blockquote {
	font-style: italic;
}

b,
strong {
	font-weight: 700;
}

img {
	object-fit: cover;
}

.primary-container {
	width: 90vw;
	max-width: $max-width;
	margin: 0 auto;
	padding: .7em 0em;
	&.block {
		margin-bottom: 15px;
		@media #{$tablet} {
			margin-bottom: 75px;
		}
	}
}

.primary-text {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	h1,
	h2,
	h3,
	h4,
	p,
	ul,
	ol,
	blockquote,
	img {
		width: 100%;
		margin-bottom: 10px;
		@media #{$tablet} {
			width: 100%;
		}
		&:last-child {
			margin-bottom: 0px;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: $color-primary;
			}
		}
	}
}

.content {
	display: block;
	@media #{$tablet} {
		padding-bottom: 0px;
	}
}

// LOGO
.logo {
	display: block;
	&:hover {
		transform: scale(1.1);
	}
	img {
		width: 300px;
		height: auto;
		@media #{$small} {
			width: 100%;
			position: relative;
			left: 0;
		}
	}
}

// Button base styles and additional classes here.
// HOVER MAIN
.content__item {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 1;
	pointer-events: none;
}

.content__item::before {
	color: $color-primary;
	position: absolute;
	top: 0;
	left: 0;
}

.button {
	pointer-events: auto;
	cursor: pointer;
	background: $white;
	border: none;
	padding: 0 1.5em;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	position: relative;
	display: inline-block;
}

.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.button--telesto {
	overflow: hidden;
	font-family:$heading;
	font-size: 1.15rem;
	color: $color-primary;
	border-radius: 1em;
}

.button--telesto span {
	display: block;
	position: relative;
	z-index: 1;
}

.button--telesto > span {
	font-size: 30px;
	color: $color-primary;
	@media #{$small} {
		font-size: 20px;
	}
	&:hover {
		color: $white;
	}
}

.button--telesto:hover > span > span {
	animation: MoveRightInitial 0.1s forwards, MoveRightEnd 0.3s forwards 0.2s;
}

@keyframes MoveRightInitial {
	to {
		transform: translate3d(105%,0,0);
	}
}

@keyframes MoveRightEnd {
	from {
		transform: translate3d(-100%,0,0);
	}
	to {
		transform: translate3d(0,0,0);
	}
}

.button--telesto::before,
.button--telesto::after {
	content: '';
	background: $othered;
	color: $white;
}

.button--telesto::before {
	width: 135%;
	-webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 0%);
	clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 0%);
	transform: translate3d(-100%,0,0);
}

.button--telesto:hover::before {
	transform: translate3d(0,0,0);
	transition: transform 0.5s cubic-bezier(0.7, 0, 0.2, 1);
}

.button--telesto::after {
	width: 105%;
	transform: translate3d(100%,0,0);
	transition: transform 0.5s cubic-bezier(0.7, 0, 0.2, 1);
}

.button--telesto:hover::after {
	transform: translate3d(0,0,0);
	transition: transform 0.03s 0.5s cubic-bezier(0.7, 0, 0.2, 1);
}


// text underline wavy
a.wavy.underline {
	font-family: $heading;
    font-size: 30px;
}

.wavy {
	&:hover{
	text-decoration-style: wavy;
	text-decoration-color: $color-secondary;
	text-decoration-skip-ink: none;
	}
  }

  .wavy.offset{
		&:hover{
	text-underline-offset: 8px;
		}
  }
  
  .underline {
	transition: $ease;
		&:hover{
		text-decoration-line: underline;
		text-decoration-color: $color-secondary;
		text-decoration-skip-ink: none;
		color: $color-secondary;
		transition: $ease;
		}
  }


  .page-id-104 {
	.button--telesto {
		background: #253068;
		padding: .5em 2em;
		margin-top: 1em;
		span {
			color: $white;
		}
	}
  }

  .page-id-106,
  .page-id-406 {
	.button--telesto>span {
		font-size: 30px;
		color: $white;
	  }
  .button {
    pointer-events: auto;
    cursor: pointer;
    background: $color-secondary;
	&:hover {
		background:$color-primary;
	}
  }
}

.page-id-406 {
	.button--telesto {
		float: left;
	    margin-top: 1.5em;
	}   
}

button.button.button--telesto.red {
    background: $color-secondary;
	&:before {
		content: "";
		background: $color-primary;
	}
	&:after {
		content: "";
		background: $color-primary;
	}
}
// All footer styles.

footer {
	width: 100vw;
	max-width: 100%;
	a {
		color: $white;
	}
	p {
		color: $white;
		text-align: center;
		line-height: 1.55em;
	}

	.footer-main,
	.footer-bottom {
		width: 100%;
		max-width: 100%;
		padding: 5px 0px;
	}
	.footer-main {
		padding: 2em 0px;
		background-color: $color-primary;
		@media #{$small} {
			padding: 0em 0px;
		}
		.primary-container {
			display: flex;
			flex-direction: column;
			max-width:100%;
			@media #{$tablet} {
				flex-direction: row;
				justify-content: space-between;
			}
			.logo {
				img {
					display: block;
					margin: auto;
					width: -webkit-fill-available;
					@media only screen and (max-width: 1000px) {
						width: min-content;
					}
		      }
	     	}

			 ul.sub-menu {
				display: none;
			}
			.logo-text,
			.location,
			.social {
				width: 100%;
				justify-content: center;
				margin: auto;
				@media #{$tablet} {
					margin-bottom: 0px;
				}
			}
			.logo-text {
				@media #{$tablet} {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
				}
				.logo {
					margin-bottom: 20px;
					margin: auto;
				}
				.text {
					color: $white;
				}
			}
			.location {
				margin-bottom: 10px;
				p {
					font-weight: bold;
					font-size: 20px;
					color: $white;
					margin-bottom: 10px;
				}
				.location-container {
					width: 100%;
					color: $white;
					line-height: 30px;
					margin: auto;
					text-align: center;
					@media #{$small} {
						margin: auto;
						text-align: center;
						padding: 1.5em 0em;
					}
					
				}
			}
			.social {
				display: flex;
				align-items: flex-start;
				@media #{$tablet} {
					padding-top: 1.5em;
				}
				img {
					width: 35px;
					height: 35px;
					object-fit: contain;
					transition: $ease;
					&:hover{
						transform: scale(1.2);
						transition: $ease;
					}
				}
				p {
					font-weight: bold;
					margin-bottom: 10px;
					font-size: 20px;
					color: $white;
				}
				.social-icons {
					width: 100%;
					display: flex;
					margin-bottom: 30px;
					a {
						margin-right: 10px;
						width: 40px;
						height: 40px;
						background-size: contain;
						&:last-child {
							margin-right: 0px;
						}
						&:hover {
							transform: scale(1.1);
						}
					}
				}
			}
			.location,
			.social {
				margin:auto;
			}
		}
	}
	.footer-bottom {
		background-color: $color-secondary;
		padding: 1em 0em;
		a {
			color: $color-primary;
			&:hover {
				text-decoration: none;
			}
		}
		.primary-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			@media #{$tablet} {
				flex-direction: row;
				justify-content: space-between;
			}
			.copyright {
				color: $white;
				margin-bottom: 10px;
				opacity: 0.5;
				text-align: center;
				@media #{$tablet} {
					margin-bottom: 0px;
					margin-right: 0px;
					width: auto;
					text-align: left;
				}
			}
			.signature {
				display: flex;
				align-items: center;
				opacity: 0.5;
				&:hover {
					opacity: 1;
				}
				img {
					width: 215px;
					height: auto;
				}
			}
		}
	}
}


.links-footer {
    text-align: center;
    margin: auto;
}

.location-container {
    margin: auto;
	text-align:center;
	@media screen and (max-width: 999px) and (min-width: 767px) {
	margin: 1em 0em;
	}
	@media #{$tablet} {
		padding: 15px 0px;
	}
	@media #{$small} {
	margin: auto;
    text-align: center;
    padding: 1em 0em;
	}
	a:nth-child(1) {
		padding-right: 1em;
	
	}
}

.nav-cta.footer {
    margin: auto;
	@media #{$tablet} {
		padding: 15px 0px;
	}
	.menu {
		.menu-item-362 {
			display: none;
		}
	}
}

.nav-cta.policies {
    margin: auto;
	@media #{$small} {
		width: 100%;
		ul#menu-policies {
			padding-top: 0;
		}
	}
	ul {
		width: 8.5% !important;
		@media #{$small} {
			width: 40% !important;
		}
	}
		li {
			a {
			color: $color-secondary !important;
			font-weight:normal !important;
			}
		}
}


.nav-cta.footer,
.nav-cta.policies {
	width: 100%;
	ul {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin: auto;
		width: 100% !important;
			@media #{$small} {
				width: 100%;
				padding-top: .5em;
			}
		li {
			width: auto;
			margin-bottom: 10px;
			padding: 0em 1em;
			a {
				display: block;
				color: $white;
				opacity: 1;
				font-weight:bold;
			}
		}
	}
}

ul#menu-header-1 .menu-item-has-children {
    display: none;
}
// All header styles.

header {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
    .header-top, .header-main {
      padding: 20px 0px;
      width: 100vw;
      max-width: 100%;
      background: $white;

    }
}

.header-top {
  background-color: $color-secondary;
  display: none;
    .primary-container {
      display: flex;
      justify-content: flex-end;
      position: relative;
      padding: .7em 0;
        .brand-name {
          display: none;
          width: auto;
          position: absolute;
          top: -5px;
          left: 0px;
          color: $white;
          transition: $ease;
          @media #{$tablet} {
            display: block;
          }
        }
        a {
          color: $white;
          margin-right: 20px;
          position: relative;
          margin-left: 40px;
          display: flex;
          align-items: center;
            &:before {
              left: -40px;
              width: 20px;
              height: 20px;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              content: "";
              position: absolute;
              top: calc(50% - 10px);
            }
        }
    }
}

.header-main {
  background-color: transparent;
  // position: relative;
  transition: $ease;
  position: fixed;
  background: $white;
    .primary-container {
      display: flex;
      justify-content: space-between;
        .logo-cta {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 50%;
          @media #{$tablet} {
            width: auto;
          }
            .logo {
              margin-right: -65px;
              @media #{$tablet} {
                margin: 0px;
              }
            }
            .button {
              font-size: 10px;
              @media #{$tablet} {
                display: none;
              }
            }
        }
        .nav-cta {
          display: flex;
          justify-content: flex-end;
          align-items: center;
           nav {
              right: 100vw;
              z-index: 1;
              transition: $ease;
              display: block;
              position: absolute;
              top: 96px;
              width: 100vw;
              max-width: 100%;
              min-height: calc(100vh - 96px);
              @media screen and (max-width: 999px) { 
                position: absolute;
                top: 20px;
                min-height: 100vh;
                height:100%;

              }
              @media #{$tablet} {
                position: static;
                width: 100%;
                min-height: 0px;
                margin-right: 20px;
              }
                &.active {
                  right: 0vw;
                }
                ul {
                  width: 85vw;
                  margin: 0 auto;
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  @media #{$tablet} {
                    width: 100%;
                    margin: 0;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    flex-wrap: wrap;
                  }
                  @media screen and (max-width: 999px) { 
                    width:100%;
                    padding:0;
                  }
                    li {
                      width: 100%;
                      @media #{$tablet} {
                        width: auto;
                        margin: 0px 0px;
                        margin-right: 25px;
                      }
                      @media screen and (max-width: 999px) { 
                        border-bottom: 1px solid $color-primary;
                      }
                      @media only screen and (max-width: 1059px) and (min-width: 1000px) {
                        margin-right: 14px;
                      }
                        a {
                          color: $color-primary;
                          padding: 20px 0px;
                          border-bottom: solid 2px $white;
                          display: block;
                          text-align: center;
                          font-size: 14px;
                          font-weight:bold;
                          @media only screen and (max-width: 999px) and (min-width: 767px) {
                            padding:15px;
                          }
                          @media #{$tablet} {
                            padding: 0px;
                            text-align: left;
                            border: none;
                          }
                          @media #{$small} {
                            border-bottom: 1px solid $color-primary;
                          }
                            &.current {
                              opacity: 1;
                              pointer-events: none;
                              color: $color-secondary !important;
                            }
                            &:hover {
                              opacity: 1;
                              color: $color-secondary;
                              @media #{$tablet} {
                                border: none;
                              }
                            }
                        }
                        &.menu-item-has-children {
                          position: relative;
                          @media #{$tablet} {
                            padding-right: 0px;
                          }
                            .dropdown-trigger {
                              width: 15px;
                              height: 15px;
                              content: "";
                              position: absolute;
                              right: 2px;
                              top: 22px;
                              background-size: contain;
                              background-position: center;
                              background-repeat: no-repeat;
                              transition: $ease;
                              cursor: pointer;
                              @media #{$tablet} {
                                top: calc(50% - 7.5px);
                                right: 0px;
                              }
                              @media screen and (max-width: 999px) {
                              right: 38px;
                              background-image: url('./images/arrow-down.png');
                            }
                            @media screen and (max-width: 999px) and (min-width: 768px) {
                              right: 85px;
                            }
                                &.active {
                                  transform: rotate(180deg);
                                }
                                &:hover {
                                  transform: scale(1.1);
                                    &.active {
                                      transform: scale(1.1) rotate(180deg);
                                    }
                                }
                            }
                            .sub-menu {
                              display: none;
                              border-left: solid 2px $white;
                              border-right: solid 2px $white;
                              @media #{$tablet} {
                                position: absolute;
                                border: none;
                                top: 19px;
                                left: -20px;
                                background-color: #ffffffe3;
                                color: $color-primary;
                                width: 250px;
                                padding: 0px;
                                margin: 0px;
                              }
                              li {
                                padding: .5em 0em;
                                border-bottom: 1px solid $color-primary;
                                margin:0;
                                color: $white;
                                padding-left: 1em;
                                @media #{$small} {
                                  border-bottom: none;
                                }
                                @media screen and (max-width: 999px) { 
                                  border-bottom: none;
                                  padding:0;
                                }
                                &:hover {
                                  border:none;
                                }
                                &:last-child {
                                  border-bottom: none;
                                }
                                a {
                                  &:hover {
                                  color: $color-secondary !important;
                                  }
                                }
                              }
                            } 
                        }
                    }
                }
            }
            .button {
              display: none;
              @media #{$tablet} {
                display: block;
              }
            }
        }
        // NAVIGATION TRIGGERS
        .nav-trigger {
          width: 25px;
          height: 30px;
          position: absolute;
          top: calc(50% - 27px);
          right: 9.5vw;
          transition: $ease;
          z-index: 2;
          background-size: contain;
          cursor: pointer;
          background-image: url('./images/hamburger.png');
          @media #{$tablet} {
            display: none;
          }
          &.active {
            background-image: url('./images/nav-close.png');
            background-size: 85%;
            transition: $ease;
          }
          &:hover {
            transform: scale(1.1);
            transition: $ease;
         }
      }
   }
}

header {
  @media #{$tablet} {
    &.tidy {
      @media #{$small} {
        display: none;
      }
        .header-top {
          .brand-name {
            opacity: 1;
          }
        }
        .header-main {
          max-height: 0px;
          opacity: 0;
          pointer-events: none;
          padding: 0px;
        }
        &:hover {
          .brand-name {
            opacity: 0;
        }
          .header-main {
            max-height: none;
            opacity: 1;
            pointer-events: all;
            padding: 25px 0px;
        }
      }
    }
  }
}

.social-icons.header {
  display: flex;
  margin-bottom: 0;
  justify-content: flex-end;
  padding-right: 3em;
  flex-direction: inherit;
  @media screen and (max-width: 999px) { 
    display:none;
  }
  a {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    background-size: contain;
    &:last-child {
      margin-right: 0px;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

ul#menu-header {
  margin: 1em 0em;
  @media screen and (max-width: 999px) { 
    background: $white;
    width: 100%;
    margin: 0;
    height: 100vh;
    overflow: scroll;
    margin-top: -5px;
  }
}

ul.sub-menu:hover a:focus {
  color: $white !important;
}



// glitch in menu fix

@media only screen and (max-width: 1190px) and (min-width: 1000px) {
  .header-main .primary-container .nav-cta nav ul li a {
    font-size: 13px !important;
  }
}
// Small misc styles not worth creating another file for.

body,
html {
	&.nav-active {
		overflow-y: hidden;
	}
}

.tablet-only {
	display: none;
	@media #{$tablet} {
		display: block;
	}
}

// 404 PAGE
.page-not-found {
	width: 100vw;
	max-width: 100%;
	background-color: $color-secondary;
	.primary-container {
		padding: 20px 0px;
		@media #{$tablet} {
			padding: 200px 0px;
			min-height: 617px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.primary-text {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			h1,
			p {
				color: $white;
			}
			.button {
				margin-top: 10px;
			}
		}
	}
}

// FLEX

.flex-container {
	display: flex;
	flex-wrap: nowrap;
	margin: auto;
	justify-content: center;
	width: 80%;
}

.flex-container > div {
	width: 100px;
	margin: 10px;
	text-align: center;
	line-height: 30px;
	font-size: 26px;
	color: $color-primary;
}

// SOCIAL ICONS

.social-icons {
	transition: $ease;
	img {
		&:hover {
			transform: scale(1.2);
			transition: $ease;
		}
	}
}


// back to top

#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
	z-index: 10;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; 
	i {
		color: $white;
		margin: 0;
		position: relative;
		left: 15px;
		top: 13px;
		font-size: 19px;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
	&:hover {
		background: rgba(94, 94, 94, 0.9);
		i {
			color: $white;
			transform:scale(1.1);
			transition: $ease;
		}
	}
}

// video

.wp-video {
    margin: auto;
	margin-bottom: 2em;
}


.single-post {
    .primary-container.block {
        width: 100%;
        max-width: 100%;
        margin-bottom: 2em;
        h1 {
            text-align: center;
            margin-top: .5em;
            margin-bottom: 0;
        }
        .primary-text p, h1 {
            width: 75%;
            margin: auto;
            margin-top: 1em;
            text-align: center;
            @media #{$small} {
                width: 85%;
                margin-top: 1em;
            }
        }
        img.attachment-full.size-full.wp-post-image {
            height: 500px;
            background-position: center;
            background-size: contain;
            @media #{$small} {
                height:300px !important;
            }
        }
    }
} 
// Define all style variables here.

// Colours.
$white: #FFFFFF;
$gray: #f5f5f5;
$searchgray: #9e9e9c;
$black: #000000;

// Brand Colours.
$color-primary: #253068;
$color-secondary: #e84241;
$darkred: #9b382e;
$othered: #bb3739;

// Header Customization

$main-menu-color: #7b7b7b;
$menu-hover-color: #303030;
$underline-menu-color: #707070;
$header-bg-colour: #FFFFFF;
$header-height: 100px;
$burger-menu-colour: #303030;
$burger-menu-bg-colour-1: #fff; // Bakcground colour underneath
$burger-menu-bg-colour-2: #fff; // Background colour on top

// Fonts
@import url("https://use.typekit.net/bco0qln.css");
$heading: 'Verveine' ,sans-serif;
$font: 'poppins',sans-serif;

// Media querys.
$small: "(max-width: 767px)";
$mobile: "(min-width: 750px)";
$tabletmid: "(max-width: 999px) and (min-width: 767px)";
$tablet: "(min-width: 1000px)";
$desktop: "(min-width: 1500px)";
$large: "(min-width: 1600px)";

// Misc.
$max-width: 1100px;
$shadow: 0 5px 10px 0 rgba(0,0,0,.05);
$ease: ease all 0.3s;
$transition-time: 750ms;

// Default classes, add these to elements to prevent repeating yourself.
.default-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.default-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.transition {
    transition: 1s ease;
    transition-delay: .2s;
    transition-timing-function: ease-in-out;
}
 
// SCSS includes.
@mixin default-psuedo {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: $ease;
}


// Generate Duration && Delay
[data-aos] {
    @for $i from 1 through 60 {
      body[data-aos-duration='#{$i * 50}'] &,
      &[data-aos][data-aos-duration='#{$i * 50}'] {
        transition-duration: #{$i * 50}ms;
      }
  
      body[data-aos-delay='#{$i * 50}'] &,
      &[data-aos][data-aos-delay='#{$i * 50}'] {
        transition-delay: 0;
  
        &.aos-animate {
          transition-delay: #{$i * 50}ms;
        }
      }
    }
  }

  $aos-easing: (
  linear: cubic-bezier(.250, .250, .750, .750),

  ease: cubic-bezier(.250, .100, .250, 1),
  ease-in: cubic-bezier(.420, 0, 1, 1),
  ease-out: cubic-bezier(.000, 0, .580, 1),
  ease-in-out: cubic-bezier(.420, 0, .580, 1),

  ease-in-back: cubic-bezier(.6, -.28, .735, .045),
  ease-out-back: cubic-bezier(.175, .885, .32, 1.275),
  ease-in-out-back: cubic-bezier(.68, -.55, .265, 1.55),

  ease-in-sine: cubic-bezier(.47, 0, .745, .715),
  ease-out-sine: cubic-bezier(.39, .575, .565, 1),
  ease-in-out-sine: cubic-bezier(.445, .05, .55, .95),

  ease-in-quad: cubic-bezier(.55, .085, .68, .53),
  ease-out-quad: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-quad: cubic-bezier(.455, .03, .515, .955),

  ease-in-cubic: cubic-bezier(.55, .085, .68, .53),
  ease-out-cubic: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-cubic: cubic-bezier(.455, .03, .515, .955),

  ease-in-quart: cubic-bezier(.55, .085, .68, .53),
  ease-out-quart: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-quart: cubic-bezier(.455, .03, .515, .955)
);

// Easings implementations
// Default timing function: 'ease'

[data-aos] {
  @each $key, $val in $aos-easing {
    body[data-aos-easing="#{$key}"] &,
    &[data-aos][data-aos-easing="#{$key}"] {
      transition-timing-function: $val;
    }
  }
}

// Animations variables
$aos-distance: 100px !default;




/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */

[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform;

  &.aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-aos='fade-up'] {
  transform: translate3d(0, $aos-distance, 0);
}

[data-aos='fade-down'] {
  transform: translate3d(0, -$aos-distance, 0);
}

[data-aos='fade-right'] {
  transform: translate3d(-$aos-distance, 0, 0);
}

[data-aos='fade-left'] {
  transform: translate3d($aos-distance, 0, 0);
}

[data-aos='fade-up-right'] {
  transform: translate3d(-$aos-distance, $aos-distance, 0);
}

[data-aos='fade-up-left'] {
  transform: translate3d($aos-distance, $aos-distance, 0);
}

[data-aos='fade-down-right'] {
  transform: translate3d(-$aos-distance, -$aos-distance, 0);
}

[data-aos='fade-down-left'] {
  transform: translate3d($aos-distance, -$aos-distance, 0);
}




/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */

[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform;

  &.aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

[data-aos='zoom-in'] {
  transform: scale(.6);
}

[data-aos='zoom-in-up'] {
  transform: translate3d(0, $aos-distance, 0) scale(.6);
}

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -$aos-distance, 0) scale(.6);
}

[data-aos='zoom-in-right'] {
  transform: translate3d(-$aos-distance, 0, 0) scale(.6);
}

[data-aos='zoom-in-left'] {
  transform: translate3d($aos-distance, 0, 0) scale(.6);
}

[data-aos='zoom-out'] {
  transform: scale(1.2);
}

[data-aos='zoom-out-up'] {
  transform: translate3d(0, $aos-distance, 0) scale(1.2);
}

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -$aos-distance, 0) scale(1.2);
}

[data-aos='zoom-out-right'] {
  transform: translate3d(-$aos-distance, 0, 0) scale(1.2);
}

[data-aos='zoom-out-left'] {
  transform: translate3d($aos-distance, 0, 0) scale(1.2);
}




/**
 * Slide animations
 */

[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform;

  &.aos-animate {
    transform: translate3d(0, 0, 0);
  }
}

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0);
}

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0);
}

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0);
}




/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */

[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg);
  &.aos-animate {transform: perspective(2500px) rotateY(0);}
}

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg);
  &.aos-animate {transform: perspective(2500px) rotateY(0);}
}

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg);
  &.aos-animate {transform: perspective(2500px) rotateX(0);}
}

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg);
  &.aos-animate {transform: perspective(2500px) rotateX(0);}
}

  
.primary-container.block.onecol {
    padding: 4em 0em;
    margin-bottom:0;
    width: 100%;
    max-width: 100%;
    @media #{$small} {
      padding: 2em 0em;
    }
    h1, h2, h3, h4, h5, h6, p, strong, b, em, ul, li {
        text-align: center;
    }
    h1, h2, h3, h4, h5, h6 {
        padding-bottom:.5em;
        padding-top:.5em;
        color: $color-secondary;
    }

    li {
      text-align: left;
      &:before {
      color: $color-secondary;
      content: "•";
      }
    }

    ul {
      text-align:left;
    }

    strong, b { 
      padding-bottom: 0.5em;
      font-family: $heading;
      font-weight: 400;
      font-size: 40px;
      line-height: inherit!important;
      @media #{$small} {
        font-size: 24px;
      }
    }
}

.page-id-102 {
    .primary-container.block.onecol {
      h1, h4 {
        padding-top: .5em;
      }
  } 
}

.home {
  .primary-container.block.onecol {
    strong, b { 
      padding-bottom:.5em;
      font-family: $heading;
      font-weight: normal;
      font-size:40px;
      line-height: inherit !important;
    }
    h1, h4 {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}
.page-id-108 {
  .primary-container.block.onecol {
    max-width:100%;
    width: 100%;
    margin: 0;
    padding: 0px;
    position: relative;
    top: .5em;
  }
}

.page-id-104 {
  .primary-container.block.onecol {
    h1 {
      padding-top:0;
    }
  }
}

strong.standard-bold {
  font-weight: bold !important;
  font-family:$font !important;
  font-size: 17px !important;
    @media #{$small} {
    font-size: 15px!important;
    }
  }

// with background
.primary-container.block.onecol.bg-color {
  padding: 1em;
  .span_list {
    padding-top: 2em;
    width: 70%;
    margin: auto;
    @media #{$small} {
      width: 100%;
    }
  }
}

.page-id-406 {
  .primary-container.block.onecol {
    margin-bottom: 1.5em;
  }
}
form#searchform {
    &:hover{
        border: 1px solid $searchgray;
        box-sizing: inherit;
    }
    input#searchsubmit {
        display: none;
    }
    .screen-reader-text {
        border: 0;
        clip: initial;
        -webkit-clip-path: 0;
        clip-path: initial;
        height: 1px;
        margin: -1px;
        overflow: inherit;
        width: 100%; 
        padding: .3em 1em;
        color: $searchgray; 
        font-weight: light;
       }
       input#s {
        padding: 1px;
        font-size: 16px;
        border: 1px solid $searchgray;

    }
}
.primary-container.block.four_circles {
	width: 100%;
    max-width: 100%;
	padding-top: 3em;
	padding-bottom: 2em;
	background: $gray;
	cursor:pointer;
	margin-bottom: 0;
	@media #{$small} {
		padding-top: 1em;
	}
	a {
    text-decoration: none;
	}
	.primary-container.circle_row_container {
	width: 60%;
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	max-width: 60%;
	    @media #{$small} {
			width: 100%;
			max-width: 100%;
			display: block;
			height: auto;
		}
		@media only screen and (min-width: 1000px) and (max-width: 1577px)  {
			width: 80%;
			max-width: 80%;
		}
	img.img-float {
    margin: auto;
    justify-content: center;
    display: block;
	width: 80%;
		@media #{$small} {
		margin-top:1.5em;
		width:auto;
		}
	}
	h2 {
		text-align: center;
		font-size: 40px;
		font-family: $heading;
		padding-bottom: 1em;
		@media only screen and (min-width: 100px) and (max-width: 1577px)  {
			font-size: 32px;
		}
		@media #{$small} {
			padding-top: .3em;
			padding-bottom: 0em;
			}
		}
  	}
	.circle_container {
		&:nth-child(1) {
			@media #{$small} {
				padding-right: 0em;
			}
		}
	}
}
.circle_container.wavy.underline {
	flex-grow: 1;
	display: inline-block;
	width: calc(100% * (1/5) - 10px - 1px);
	
	@media only screen and (min-width: 767px) and (max-width: 999px)  {
		width: calc(100% * (1/2) - 10px - 1px);
	}

	@media #{$small} {
    display: block;
    width: 100%;
    margin: auto;
	}
}
// Generate Duration && Delay
[data-aos] {
    @for $i from 1 through 60 {
      body[data-aos-duration='#{$i * 50}'] &,
      &[data-aos][data-aos-duration='#{$i * 50}'] {
        transition-duration: #{$i * 50}ms;
      }
  
      body[data-aos-delay='#{$i * 50}'] &,
      &[data-aos][data-aos-delay='#{$i * 50}'] {
        transition-delay: 0;
  
        &.aos-animate {
          transition-delay: #{$i * 50}ms;
        }
      }
    }
  }

  $aos-easing: (
  linear: cubic-bezier(.250, .250, .750, .750),

  ease: cubic-bezier(.250, .100, .250, 1),
  ease-in: cubic-bezier(.420, 0, 1, 1),
  ease-out: cubic-bezier(.000, 0, .580, 1),
  ease-in-out: cubic-bezier(.420, 0, .580, 1),

  ease-in-back: cubic-bezier(.6, -.28, .735, .045),
  ease-out-back: cubic-bezier(.175, .885, .32, 1.275),
  ease-in-out-back: cubic-bezier(.68, -.55, .265, 1.55),

  ease-in-sine: cubic-bezier(.47, 0, .745, .715),
  ease-out-sine: cubic-bezier(.39, .575, .565, 1),
  ease-in-out-sine: cubic-bezier(.445, .05, .55, .95),

  ease-in-quad: cubic-bezier(.55, .085, .68, .53),
  ease-out-quad: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-quad: cubic-bezier(.455, .03, .515, .955),

  ease-in-cubic: cubic-bezier(.55, .085, .68, .53),
  ease-out-cubic: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-cubic: cubic-bezier(.455, .03, .515, .955),

  ease-in-quart: cubic-bezier(.55, .085, .68, .53),
  ease-out-quart: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-quart: cubic-bezier(.455, .03, .515, .955)
);

// Easings implementations
// Default timing function: 'ease'

[data-aos] {
  @each $key, $val in $aos-easing {
    body[data-aos-easing="#{$key}"] &,
    &[data-aos][data-aos-easing="#{$key}"] {
      transition-timing-function: $val;
    }
  }
}

// Animations variables
$aos-distance: 100px !default;




/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */

[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform;

  &.aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-aos='fade-up'] {
  transform: translate3d(0, $aos-distance, 0);
}

[data-aos='fade-down'] {
  transform: translate3d(0, -$aos-distance, 0);
}

[data-aos='fade-right'] {
  transform: translate3d(-$aos-distance, 0, 0);
}

[data-aos='fade-left'] {
  transform: translate3d($aos-distance, 0, 0);
}

[data-aos='fade-up-right'] {
  transform: translate3d(-$aos-distance, $aos-distance, 0);
}

[data-aos='fade-up-left'] {
  transform: translate3d($aos-distance, $aos-distance, 0);
}

[data-aos='fade-down-right'] {
  transform: translate3d(-$aos-distance, -$aos-distance, 0);
}

[data-aos='fade-down-left'] {
  transform: translate3d($aos-distance, -$aos-distance, 0);
}




/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */

[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform;

  &.aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

[data-aos='zoom-in'] {
  transform: scale(.6);
}

[data-aos='zoom-in-up'] {
  transform: translate3d(0, $aos-distance, 0) scale(.6);
}

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -$aos-distance, 0) scale(.6);
}

[data-aos='zoom-in-right'] {
  transform: translate3d(-$aos-distance, 0, 0) scale(.6);
}

[data-aos='zoom-in-left'] {
  transform: translate3d($aos-distance, 0, 0) scale(.6);
}

[data-aos='zoom-out'] {
  transform: scale(1.2);
}

[data-aos='zoom-out-up'] {
  transform: translate3d(0, $aos-distance, 0) scale(1.2);
}

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -$aos-distance, 0) scale(1.2);
}

[data-aos='zoom-out-right'] {
  transform: translate3d(-$aos-distance, 0, 0) scale(1.2);
}

[data-aos='zoom-out-left'] {
  transform: translate3d($aos-distance, 0, 0) scale(1.2);
}




/**
 * Slide animations
 */

[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform;

  &.aos-animate {
    transform: translate3d(0, 0, 0);
  }
}

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0);
}

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0);
}

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0);
}




/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */

[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg);
  &.aos-animate {transform: perspective(2500px) rotateY(0);}
}

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg);
  &.aos-animate {transform: perspective(2500px) rotateY(0);}
}

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg);
  &.aos-animate {transform: perspective(2500px) rotateX(0);}
}

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg);
  &.aos-animate {transform: perspective(2500px) rotateX(0);}
}

  
// Button base styles and additional classes here.
// HOVER MAIN
.content__item {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 1;
	pointer-events: none;
}

.content__item::before {
	color: $color-primary;
	position: absolute;
	top: 0;
	left: 0;
}

.button {
	pointer-events: auto;
	cursor: pointer;
	background: $white;
	border: none;
	padding: 0 1.5em;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	position: relative;
	display: inline-block;
}

.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.button--telesto {
	overflow: hidden;
	font-family:$heading;
	font-size: 1.15rem;
	color: $color-primary;
	border-radius: 1em;
}

.button--telesto span {
	display: block;
	position: relative;
	// z-index: 1;
}

.button--telesto > span {
	font-size: 30px;
	color: $color-primary;
	@media #{$small} {
		font-size: 20px;
	}
	&:hover {
		color: $white;
	}
}

.button--telesto:hover > span > span {
	animation: MoveRightInitial 0.1s forwards, MoveRightEnd 0.3s forwards 0.2s;
}

@keyframes MoveRightInitial {
	to {
		transform: translate3d(105%,0,0);
	}
}

@keyframes MoveRightEnd {
	from {
		transform: translate3d(-100%,0,0);
	}
	to {
		transform: translate3d(0,0,0);
	}
}

.button--telesto::before,
.button--telesto::after {
	content: '';
	background: $othered;
	color: $white;
}

.button--telesto::before {
	width: 135%;
	-webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 0%);
	clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 0%);
	transform: translate3d(-100%,0,0);
}

.button--telesto:hover::before {
	transform: translate3d(0,0,0);
	transition: transform 0.5s cubic-bezier(0.7, 0, 0.2, 1);
}

.button--telesto::after {
	width: 105%;
	transform: translate3d(100%,0,0);
	transition: transform 0.5s cubic-bezier(0.7, 0, 0.2, 1);
}

.button--telesto:hover::after {
	transform: translate3d(0,0,0);
	transition: transform 0.03s 0.5s cubic-bezier(0.7, 0, 0.2, 1);
}


// text underline wavy
a.wavy.underline {
	font-family: $heading;
    font-size: 30px;
}

.wavy {
	&:hover{
	text-decoration-style: wavy;
	text-decoration-color: $color-secondary;
	text-decoration-skip-ink: none;
	}
  }

  .wavy.offset{
		&:hover{
	text-underline-offset: 8px;
		}
  }
  
  .underline {
	transition: $ease;
		&:hover{
		text-decoration-line: underline;
		text-decoration-color: $color-secondary;
		text-decoration-skip-ink: none;
		color: $color-secondary;
		transition: $ease;
		}
  }


  .page-id-104 {
	.button--telesto {
		background: #253068;
		padding: .5em 2em;
		margin-top: 1em;
		span {
			color: $white;
		}
	}
  }

  .page-id-106,
  .page-id-406 {
	.button--telesto>span {
		font-size: 30px;
		color: $white;
	  }
  .button {
    pointer-events: auto;
    cursor: pointer;
    background: $color-secondary;
	&:hover {
		background:$color-primary;
	}
  }
}

.page-id-406 {
	.button--telesto {
		float: left;
	    margin-top: 1.5em;
	}   
}
.gallery {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 5vw);
    grid-gap: 1.5rem; 
  }
  
  .gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; 
  }
  
  .gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  
    /** Alternative Syntax **/
    /* grid-column: 1 / span 2;  */
    /* grid-row: 1 / span 2; */
  }
  
  .gallery__item--2 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
  
    /** Alternative Syntax **/
    /* grid-column: 3 / span 2;  */
    /* grid-row: 1 / span 2; */
  }
  
  .gallery__item--3 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 6;
  
    /** Alternative Syntax **/
    /* grid-column: 5 / span 4;
    grid-row: 1 / span 5; */
  }
  
  .gallery__item--4 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 6;
  
    /** Alternative Syntax **/
    /* grid-column: 1 / span 4;  */
    /* grid-row: 3 / span 3; */
  }
  
  .gallery__item--5 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 9;
  
    /** Alternative Syntax **/
    /* grid-column: 1 / span 4; */
    /* grid-row: 6 / span 3; */
  }
  
  .gallery__item--6 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 6;
    grid-row-end: 9;
  
    /** Alternative Syntax **/
    /* grid-column: 5 / span 4; */
    /* grid-row: 6 / span 3; */
  }
.primary-container.block.image_repeater {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0em;
    text-align: center;
    display: flex;
    width: 100%;
    max-width: 100% !important;
    padding-bottom: 5em;
    margin-bottom: 0;
    @media screen and (max-width: 999px) and (min-width: 767px) {
        display: inline-block;
        padding-bottom: 1em;
    }
    @media #{$small} {
        display:block;
        padding-bottom: 1em;
      }
    img.affliate_logo {
        margin: 0;
        justify-content: center;
        display: flex;
        text-align: center;
        padding: 0em 1em;
        @media screen and (max-width: 999px) and (min-width: 767px) {
          width: auto;
          padding: 0em 1.5em;
          display: inline-block;
        }
        &:hover {
          filter: brightness(0.5);
        }
        @media #{$small} {
          display: inline-block;
          width: auto;
          justify-content: center;
          margin: auto;
          vertical-align: unset;
        }
    }
}

.page-id-406 {
  .primary-container.block.image_repeater {
    display: block;
    width:90%;
    padding: 0;
  }
  .primary-container.block.image_repeater img.affliate_logo {
    margin: 0;
    justify-content: center;
    display: inline-flex;
    text-align: center;
    padding: 0 !important;
  }
  img.affliate_logo {
    transition: $ease;
    padding: 0 !important;
    &:hover {
      filter: none !important;
      transform: scale(1.1);
      transition: $ease;
      }
   }
   .primary-container.block.onecol {
    padding-bottom: 1em;
  }
}


.primary-container.block.info_box {
    width: 100%;
    max-width: 100%;
    margin: auto;
    justify-content: center;
    text-align: center;
    background: $color-primary;
    a {
        color: $color-secondary;
    }
    .copy_content {
        padding-bottom: 1.5em;
    }
    h1, h2, h3, h4, h5, h6, strong, p, b {
    color: $white;
    }
    h3 {
        @media only screen and (max-width: 600px) { 
            font-size:30px;
        }
    }
    .images {
        padding: 1em 0em;
        background: $white;
    img {
        padding: .5em;
        width: 33%;
        @media only screen and (max-width: 600px) {
            width:100%;
            padding: 0em;
        }
        @media screen and (max-width: 850px) and (min-width:601px) {
            padding: .5em;
            width: 43%;
            vertical-align: middle;
        }
        &:nth-child(1){
            padding-left:0;
        }
        &:nth-child(3){
            padding-right:0;
         }
        }
    }
}
.job_vacancy {
    padding: 2em 0em;
    &:nth-child(1) {
        padding-top:0;
    }
    h4.green.mb-0 {
        font-size: 40px;
    }
    .button--telesto>span {
        font-size: 25px;
    }
}
.primary-container.block.onecol {
    padding: 4em 0em;
    margin-bottom:0;
    width: 100%;
    max-width: 100%;
    @media #{$small} {
      padding: 2em 0em;
    }
    h1, h2, h3, h4, h5, h6, p, strong, b, em, ul, li {
        text-align: center;
    }
    h1, h2, h3, h4, h5, h6 {
        padding-bottom:.5em;
        padding-top:.5em;
        color: $color-secondary;
    }

    h2 {
        font-size: 50px;
        font-family: $heading;
        color: $color-secondary;
        @media #{$small} {
          font-size: 40px;
        }
    }

    li {
      text-align: left;
      &:before {
      color: $color-secondary;
      content: "•";
      }
    }

    ul {
      text-align:left;
      @media #{$small} {
      margin-left: 0.5em;
      }
    }

    ul li {
      @media #{$small} {
      text-indent: -0.55em;
      list-style-position: inside;
      font-size: 15px;
      line-height: 20px;
      padding-top: 4px;
      }
      &:nth-child(1) {
        padding-top:0;
      }
  }

    strong, b { 
      padding-bottom: 0.5em;
      font-family: $heading;
      font-weight: 400;
      font-size: 40px;
      line-height: inherit!important;
      @media #{$small} {
        font-size: 24px;
      }
    }
}

.page-id-102 {
    .primary-container.block.onecol {
      h1, h4 {
        padding-top: .5em;
      }
  } 
}

.home {
  .primary-container.block.onecol {
    strong, b { 
      padding-bottom:.5em;
      font-family: $heading;
      font-weight: normal;
      font-size:40px;
      line-height: inherit !important;
    }
    h1, h4 {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}
.page-id-108 {
  .primary-container.block.onecol {
    max-width:100%;
    width: 100%;
    margin: 0;
    padding: 0px;
    position: relative;
    top: .5em;
  }
}

.page-id-104 {
  .primary-container.block.onecol {
    h1 {
      padding-top:0;
    }
  }
}

strong.standard-bold {
  font-weight: bold !important;
  font-family:$font !important;
  font-size: 17px !important;
    @media #{$small} {
    font-size: 15px!important;
    }
  }

// with background
.primary-container.block.onecol.bg-color {
  padding: 1em;
  .span_list {
    padding-top: 2em;
    width: 70%;
    margin: auto;
    padding-bottom: 3em;
    @media #{$small} {
      width: 100%;
      padding-top:1em;
      padding-bottom:0;
      font-size:15px;
    }
  }
} 

img.alignnone.size-medium.wp-image-431 {
  @media #{$tablet} {
  object-fit: contain;
  width: 22%;
  }
  @media screen and (max-width: 999px) { 
    width: 60%;
    object-fit: contain;
    margin: 1em 0em;
  }
}

img.qrcode {
  margin-top: 5em;
  text-align: left;
  display: flex;
}

.page-id-117 {
  .primary-container.block.onecol {
    strong {
    padding-bottom: 0;
    font-family: $font;
    font-weight: bold;
    font-size: 17px;
    line-height: inherit!important;
    }
  p {
    text-align: left ;
  }
  ul {
    padding: 1em 0em;
  }
  h3 {
    text-align: left !important;
    @media screen and (max-width: 767px) { 
      font-size:33px !important;
    }
  }
 } 
}
.primary-container.block.shortcode {
  text-align: center;
  background: $gray;
  max-width:100%;
  width:100%;
  padding: 4em 0em;
  @media #{$small} {
    padding: 2em 0em;
  }
    h3 {
      color: $color-secondary;
      text-align:center;
      padding-bottom:.5em;
    }
    a.btn-one {
      font-size: 30px;
      color: $color-primary;
      font-family: $heading;
      @media #{$small} {
        font-size: 20px;
     }
   }
   h4 {
    font-size: 17px;
    padding-top: 1em;
    font-family: $font;
    @media #{$small} {
      font-size: 14px;
   }
   }
   h5.strapline_blog {
    font-size: 40px;
    padding-top:1em;
    font-family: $heading;
    font-weight: normal;
    @media #{$small} {
      font-size: 30px;
   }
  }
  img.blog_image {
    width: 85%;
    text-align: center;
    justify-content: center;
    display: block;
    margin: auto;
  }
  
.slick-dots li button:before {
  font-size: 40px !important;
  color: #e84241 !important;
}

ul.slick-dots {
  position: relative !important;
  li {
    width: 12px !important;
  }
}

.slick-dots {
  bottom: -15px !important;
}
}

.page-id-108 {
  .primary-container.block.shortcode {
  margin-bottom: 0;
  padding: 0;
  }
}


// slick 

.primary-container.block.projects.shortcode {
  margin-bottom: 0;
  justify-content: center;
  padding-top: 0;
  background: $white;
  display: block;
  grid-template-columns: auto;
  }

  .slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
     color: $color-secondary !important;
     font-weight:bold;
  }

  .Modern-Slider-blogs.slick-initialized.slick-slider {
      height: auto;
  }

  ul.slick-dots li {
    width: 25px !important;
  }
// CONTAINER OF TEAM SECTION
.meet-the-team {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	background: $white;
	h2 {
		margin-bottom: 15px;
		padding-top: 1em;
		text-align: center;
		@media #{$tablet} {
			margin-bottom: 50px;
			font-size: 30px;
		}
		p {
			@media #{$small} {
				text-align: center;
			}
		}
	}

	// CONTAINER OF EACH TEAM MEMBER
	.profile-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@media #{$small} {
			padding: 0em 1em 0em 1em;
		}
		@media #{$tabletmid} {
			padding-left: 2em;
			flex-direction: row;
			padding-right: 2em;
		}
		@media #{$tablet} {
			flex-direction: row;
			max-width: 1500px;
			margin: auto;
		}
		.profile {
			width: 100%;
			display: flex;
			flex-direction: column;
			margin-bottom: 15px;
			padding-bottom: 3em;
			@media #{$tabletmid} {
				padding-left: 1em;
				padding-right: 1em;
			}
			@media #{$tablet} {
				width: 30%;
				margin-bottom: 0px;
			}

			&:last-child {
				margin-bottom: 0px;
			}
			img {
				margin-bottom: 20px;
				width: 100%;
				object-fit: cover;
				border-radius: 10em 15em 10em 15em;
				height: 250px;
				transition: $ease;
				@media #{$mobile} {
					height: 350px;
				}
				@media #{$small} {
					padding: 0em 1em 0em 1em;
					border-radius: 0px;
					object-fit: contain;
				}
				@media #{$tabletmid} {
					padding: 1em;
					border-radius: 0px;
				}
				&:hover {
					border-radius: 0;
					transition: $ease;
				}
			}

			// TITLE
			.name-title-container {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-end;
				margin-bottom: 15px;
				@media #{$small} {
					text-align: center;
					margin: auto;
					justify-content: center;
					display: block;
				}
				@media #{$tablet} {
					margin-bottom: 0px;
				}

				@media #{$tabletmid} {
					margin-bottom: 0px;
					display: block;
				}
				h2 {
					margin-right: 20px;
				}
				h3 {
					margin-bottom: -2px;
					text-transform: uppercase;
					font-size: 12px;
					@media #{$tablet} {
						margin-bottom: 0px;
					}
				}
			}
		}
	}
}
// SOCIAL BUTTON
a.linkedin-img {
	img {
		height: auto;
		border-radius: 0px;
		float: right;
		position: relative;
		top: 17px;
		transition: $ease;
		&:hover {
			transform: scale(1.1);
			transition: $ease;
		}
		@media #{$small} {
			top: 0.5em;
			transition: all 0.3s ease;
			width: auto;
			text-align: center;
			margin: auto;
			justify-content: center;
			float: none;
			padding-left: 0px;
		}
		@media #{$tabletmid} {
			top: 0.5em;
			transition: all 0.3s ease;
			width: auto;
			text-align: center;
			margin: auto;
			justify-content: center;
			float: none;
			padding: 0px;
		}
	}
}

.primary-container.block.twocol {
    margin-bottom:0;
    .one_column_content {
        text-align: center;
        padding-top: 3em;
        h2 {
            font-family: $heading;
        }
    }
}
.page-id-108 {
    .primary-container.block.onecol .primary-container.row {
        width: 100%;
        max-width: 100%;
    }
}

button#wpforms-submit-165 {
    background: #253067;
    color: #fff;
    letter-spacing: 1px;
}

.wpforms-confirmation-container-full, div[submit-success]>.wpforms-confirmation-container-full:not(.wpforms-redirection-message) {
    background: transparent !important;
    border: 3px solid $color-secondary !important;
    text-align: center;
    margin-top: 2em !important;
}
.header_layout-1 {
	width: 100%;
    max-width: 100%;
    background-position: center;
    background-size: cover;
    padding: 0;
    overflow: hidden;
    margin-top: 6em;
	height: 350px;
	@media only screen and (max-width: 1200px) {
		background-size: cover;
		margin-top: 6em;
	}
	@media screen and (min-width: 2000px) {
		margin-top: 7em !important;
		background-position: revert !important;
	}
	.primary_container {
		display: flex;
		justify-content: center;
		margin: 0;
		width: 100%;
		max-width: 100%;
		.header_text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			@media #{$tablet} {
				align-items: flex-start;
			}
			.text_button {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;
				@media #{$tablet} {
					flex-direction: row;
					justify-content: space-between;
				}
				@media #{$small} {
					margin-bottom: 0px;
				}
				h1 {
					text-align: center;
					margin-bottom: 20px;
					color: $white;
					@media #{$tablet} {
						text-align: center;
						margin-bottom: 0px;
					}
				}
				.button {
					border-color: $black;
					color: $black;
					&:hover {
						border-color: $color-primary;
						color: $color-primary;
					}
				}
			}
			h2 {
				text-align: center;
				@media #{$tablet} {
					text-align: center;
				}
			}
		}
	}
	&.blog-header {
		.primary_container {
			.header_text {
				.text_button {
					margin-bottom: 0px;
				}
			}
		}
	}
}

.header_layout_2 {
	width: 100%;
	max-width: 100%;
	padding: 10em 0;
	background-size: cover;
    background-position: bottom;
	margin-bottom: 0;
	background-attachment: fixed;
	@media #{$small} {
		background-attachment: inherit;
	}
	.standard_text {
		width: 100%;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: $white;
		}

		@media #{$small} {
			display: block;
			margin: auto;
		}
		h2, p {
			color: $white;
			line-height:1em;
			font-size:40px;
			text-align: center;
			font-family: $heading;
			justify-content: center;
			margin: auto;
			padding-bottom: .5em;
		}

		a.btn-one {
			font-size: 30px;
			background: $white;
			color: $color-primary;
			font-family: $heading;
			padding: 0em 1.5em;
			border-radius: 1em;
	}
}
.img-fill {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  img {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    max-width: none;
  }
}

.blocks-box, .slick-slider {
  margin: 0;
  padding: 0 !important;
}

.slick-slide {
  float: left;
  padding: 0;
}

/* ==== Slider Style === */

.Modern-Slider {
  position: relative;
  color: $white;
  .item {
    .img-fill {
        height:565px;
        // @media #{$small} {
        //   height:90vh;
        // }
      .info {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        line-height: 73vh;
        text-align: center;
        color: $white;
        @media #{$small} {
          line-height: 55vh;
        }
        @media only screen and (max-width: 1299px) and (min-width: 1000px) {
          line-height: 66vh !important;
        }
      }
    }

    .info > div {
      display: inline-block !important;
      vertical-align: middle;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 15px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
      display: inline-flex;
      margin: 0 0px;
      padding: 0;
      cursor: pointer;
      width: 35px;
      height: 0px;
      &:hover {
        background-color: $color-secondary;
      }
      &.slick-active {
        animation: ProgressDots 11s both;
      }
    }
  }

  .item {
    &.slick-active {
      animation: zoomInImage 1s both;
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      transition: all 1s ease;
      color: $white;
      h1 {
        color: $white;
        @media only screen and (max-width: 1299px) and (min-width: 1000px) {
          font-size: 30px;
        }
      }
    }
  }
}

.slick-dotted.slick-slider {
margin-bottom: 0px !important;
}


section.hero {
  margin-top: 4em;
  position: relative;
   @media #{$small} {
    margin-top: 0em;
   }
}

img.circle_vector {
  width: 50%;
  margin: auto;
  @media #{$small} {
    top: 15em;
  }
 
}

.slick-next {
  right: 0 !important;
}

.Modern-Slider .slick-dots li button:before {
  font-size: 40px !important;
  color: $white !important;
  opacity: 1 !important;
}

// mobile 
@media screen and (min-width: 601px) { 
section.hero.mobile {
      display:none;
  }
}

@media screen and (max-width: 600px) {
section.hero.desktop {
    display:none;
  }

  section.hero.mobile {
   margin-top:4em;
   position: relative;
 }
}
@media screen and (min-width: 2000px) {
  .Modern-Slider.slick-initialized.slick-slider.slick-dotted {
    position: relative;
    margin-top: 8em!important;
  }
}